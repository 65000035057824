import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';

const { REACT_APP_BACKEND_URL } = process.env;

export default function getApiClient(
    type: 'admin' | 'user' | undefined = undefined
): AxiosInstance {
    const headers: AxiosRequestHeaders = {};

    const token = localStorage.getItem(`${type}Token`);

    if (token) headers.Authorization = `Bearer ${token}`;

    const instance = axios.create({
        baseURL: REACT_APP_BACKEND_URL || '/api/v1/',
        responseType: 'json',
        headers,
    });
    instance.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.response.status === 401) {
                localStorage.removeItem(`${type}Token`);
            }
            return Promise.reject(error);
        }
    );
    return instance;
}
