import React from 'react';
import './CandidateRegistrationPage.scss';
import CandidateForm from 'forms/forms/CandidateForm';
import { ICandidateForm } from 'store/reducers/elections/types';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
    asyncCreateCandidate,
    asyncFetchRegistrationStatus,
} from 'store/reducers/elections/thunks';
import SuccessCard from 'components/SuccessCarc/SuccessCard';
import { QuestionProps } from 'components/ui/Question/Question';
import QuestionBlock from 'containers/QuestionBlock';
import { message } from 'antd';

const questions: QuestionProps[] = [
    {
        header: 'Я не уверен(а), что правильно заполнил(а) анкету',
        text: (
            <>
                Все вопросы по заполнению анкеты можно уточнить в избиркоме
                Студенческого совета СПбГУТ
            </>
        ),
    },
    {
        header: 'Я допустил(а) ошибку в анкете',
        text: (
            <>
                Не переживай, всё поправимо, для этого напиши в{' '}
                <a
                    href="https://t.me/dobroplat"
                    target="_blank"
                    rel="noreferrer"
                >
                    Telegram
                </a>{' '}
                или{' '}
                <a
                    href="https://vk.com/vladhasnoid"
                    target="_blank"
                    rel="noreferrer"
                >
                    VK
                </a>
            </>
        ),
    },
];

function CandidateRegistrationPage(): React.ReactElement {
    const dispatch = useAppDispatch();

    const { registrationStatus } = useAppSelector(
        state => state.electionsStore
    );

    const [registrationCompleted, setCanRegister] =
        React.useState(registrationStatus);

    React.useEffect(() => {
        setCanRegister(registrationStatus);
    }, [registrationStatus]);

    const [form, setForm] = React.useState<ICandidateForm>({
        picture: undefined,
        vk_nick: '',
        telegram_nick: '',
        program_text: '',
        description_text: '',
    });
    const [messageApi, contextHolder] = message.useMessage();

    return (
        <>
            <div>
                {contextHolder}
                {!registrationCompleted && (
                    <CandidateForm
                        onSubmit={() => {
                            dispatch(asyncFetchRegistrationStatus());
                            dispatch(asyncCreateCandidate(form))
                                .unwrap()
                                .then(() => setCanRegister(true))
                                .catch(error => {
                                    messageApi.warning(
                                        `Проверьте корректность 
                                        заполненных полей`
                                    );
                                });
                        }}
                        form={form}
                        setForm={setForm}
                    />
                )}
                {registrationCompleted && <SuccessCard />}
            </div>

            <div>
                <h3>Вопросы и ответы</h3>
                <QuestionBlock questions={questions} />
            </div>
        </>
    );
}

export default React.memo(CandidateRegistrationPage);
