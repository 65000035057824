import React from 'react';
import './ElectionPage.scss';
import CandidateCard from 'components/elections/CandidateCard';
import { useAppSelector } from 'store/store';

function ElectionPage(): React.ReactElement {
    const { election } = useAppSelector(store => store.electionsStore);
    return (
        <div>
            <h3>Кандидаты</h3>
            <div className="candidates-list">
                {election?.candidates.length === 0 && <h3>Отсутствуют</h3>}
                {election?.candidates.map(candidate => (
                    <CandidateCard key={candidate.id} candidate={candidate} />
                ))}
            </div>
        </div>
    );
}

export default React.memo(ElectionPage);
