import React from 'react';
import './Button.scss';

export interface ButtonProps {
    children: React.ReactNode | React.ReactNode[];
    size: 'l' | 'm' | 's' | 'xs';
    color: 'black' | 'white' | 'green' | 'transparent';
    disabled?: boolean;
    block?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function Button({
    children,
    size,
    color,
    disabled,
    onClick,
    block,
}: ButtonProps): React.ReactElement {
    const disabledClass = disabled ? 'disabled' : '';
    const blockClass = block ? 'block' : '';
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            type="button"
            className={`button 
                        button--${color} 
                        button--${size} 
                        ${disabledClass} 
                        ${blockClass}
                        `}
        >
            {children}
        </button>
    );
}

Button.defaultProps = {
    disabled: false,
    block: false,
    onClick: () => null,
};

export default React.memo(Button);
