import React from 'react';
import './Footer.scss';
import { ReactComponent as SSFullLogo } from 'assets/icons/SSFullLogo.svg';
import { ReactComponent as SutLogo } from 'assets/icons/sutLogo.svg';
import { ReactComponent as SSFooterLogo } from 'assets/icons/SSFooterLogo.svg';

interface FooterMenuLink {
    label: string;
    url: string;
}

interface FooterMenuProps {
    header: string;
    links: FooterMenuLink[];
}

function FooterMenu({ header, links }: FooterMenuProps): React.ReactElement {
    return (
        <div className="footer-menu">
            <div className="footer-menu_header">{header}</div>
            {links.map(link => (
                <a key={link.url} href={link.url}>
                    {link.label}
                </a>
            ))}
        </div>
    );
}

function Footer(): React.ReactElement {
    const footerMenus: FooterMenuProps[] = [
        {
            header: 'Студенческий совет СПбГУТ',
            links: [
                {
                    url: 'https://vk.com/studsovet.bonch',
                    label: 'ВКонтакте',
                },
                {
                    url: 'https://www.sut.ru/studentu/studencheskiy-sovet',
                    label: 'На сайте СПбГУТ',
                },
                {
                    url: 'https://vk.com/ingut',
                    label: 'InGUT — Студенческие новости',
                },
            ],
        },
    ];

    return (
        <footer className="card-3">
            <div className="background-logo">
                <SSFooterLogo />
            </div>
            <SSFullLogo />
            <p>
                © 2005 — 2023 <br />
                Студенческий совет СПбГУТ <br />
                Designed by{' '}
                <a
                    target="_blank"
                    href="https://linktr.ee/brsv_alx"
                    rel="noreferrer"
                >
                    Alexander Borisov
                </a>
                , powered by{' '}
                <a target="_blank" href="https://crysto.ru" rel="noreferrer">
                    Crysto
                </a>
                .
            </p>
            <div className="menus">
                {footerMenus.map(footerMenu => (
                    <FooterMenu
                        key={footerMenu.header}
                        header={footerMenu.header}
                        links={footerMenu.links}
                    />
                ))}
            </div>
            <a
                className="sut-logo"
                href="https://www.sut.ru"
                target="_blank"
                rel="noreferrer"
            >
                <SutLogo />
            </a>
        </footer>
    );
}

export default React.memo(Footer);
