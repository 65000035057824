import React from 'react';
import './Switch.scss';

export interface SwitchValueProps {
    key: string;
    name: string;
}

export interface SwitchProps {
    values: SwitchValueProps[];
    value: string;
    onChange: (value: string) => void;
    maxWidth?: string;
}

function Switch({
    maxWidth,
    values,
    value,
    onChange,
}: SwitchProps): React.ReactElement {
    return (
        <div
            className="switch"
            style={{
                gridTemplateColumns: `repeat(${values.length}, 1fr)`,
                maxWidth: maxWidth || 'max-content',
            }}
        >
            {values.map(iterValue => (
                <div
                    key={iterValue.key}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => null}
                    onClick={() => onChange(iterValue.key)}
                    className={`selector ${
                        value === iterValue.key ? 'selected' : ''
                    }`}
                >
                    {iterValue.name}
                </div>
            ))}
        </div>
    );
}

Switch.defaultProps = {
    maxWidth: null,
};

export default React.memo(Switch);
