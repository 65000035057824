import React from 'react';
import 'components/Announcement/Announcement.scss';
import Button from 'components/ui/Button';
import { ReactComponent as Bracers } from 'assets/icons/bracers.svg';

export interface AnnouncementProps {
    children: React.ReactNode | React.ReactNode[];
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    buttonText: string;
    buttonDisabled: boolean;
}

function Announcement({
    children,
    onClick,
    buttonText,
    buttonDisabled,
}: AnnouncementProps): React.ReactElement {
    return (
        <div className="card-1 announcement">
            <Bracers />
            <div className="announcement-grid">
                <h1>{children}</h1>
                <Button
                    disabled={buttonDisabled}
                    onClick={onClick}
                    color="black"
                    size="m"
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
}

export default React.memo(Announcement);
