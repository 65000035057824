import React from 'react';
import './QuestionBlock.scss';
import { QuestionProps } from 'components/ui/Question/Question';
import Question from 'components/ui/Question';

export interface QuestionBlockProps {
    questions: QuestionProps[];
}

function QuestionBlock({ questions }: QuestionBlockProps): React.ReactElement {
    return (
        <div className="question-block">
            {questions.map(question => (
                <Question
                    key={question.header}
                    header={question.header}
                    text={question.text}
                />
            ))}
        </div>
    );
}

export default React.memo(QuestionBlock);
