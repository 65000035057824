import React from 'react';

import { RouteObject } from 'react-router-dom';
import MainPage from 'pages/main/main/MainPage';
import MainOutlet from 'pages/main/MainOutlet';
import CandidateRegistrationPage from 'pages/main/candidateRegistration/CandidateRegistrationPage';
import ElectionOutlet from 'pages/main/elections/ElectionOutlet';
import ElectionPage from 'pages/main/elections/election/ElectionPage';
import ElectionsPage from 'pages/admin/elections/ElectionsPage';
import CandidatePage from 'pages/main/elections/candidate/CandidatePage';
import AdminOutlet from 'pages/admin/AdminOutlet';
import LoginPage from 'pages/admin/login/LoginPage';
import AdminElectionPage from 'pages/admin/elections/election/AdminElectionPage';
import AdminElectionResultsPage from 'pages/admin/elections/results/AdminElectionResultsPage';

export const routes: RouteObject[] = [
    {
        element: <MainOutlet />,
        children: [
            {
                element: <MainPage />,
                index: true,
            },
            {
                element: <CandidateRegistrationPage />,
                path: 'candidates',
            },
            {
                element: <ElectionOutlet />,
                path: 'elections/:electionId',
                children: [
                    {
                        element: <ElectionPage />,
                        index: true,
                    },
                    {
                        element: <CandidatePage />,
                        path: 'candidates/:candidateId',
                    },
                ],
            },
        ],
    },
    {
        element: <AdminOutlet />,
        path: 'admin',
        children: [
            {
                element: <LoginPage />,
                path: 'login',
            },
            {
                element: <ElectionsPage />,
                index: true,
            },
            {
                element: <AdminElectionPage />,
                path: 'elections/:electionId',
            },
            {
                element: <AdminElectionResultsPage />,
                path: 'elections/:electionId/results',
            },
        ],
    },
];

export default routes;
