import React from 'react';
import './AvatarInput.scss';
import { ReactComponent as CameraIcon } from 'assets/icons/mingcute_camera-2-fill.svg';

export interface AvatarInputProps {
    setFile: (value: Blob) => void;
}

function AvatarInput({ setFile }: AvatarInputProps): React.ReactElement {
    const [pictureUrl, setPictureURL] = React.useState<string | null>(null);

    function handleUpload({ target }: React.FormEvent<HTMLInputElement>): void {
        const { files } = target as HTMLInputElement;
        if (!files) return;
        setFile(files[0] as Blob);
        setPictureURL(URL.createObjectURL(files[0]));
    }

    return (
        <label htmlFor="file-upload">
            <input
                accept=".jpg, .png, .jpeg"
                id="file-upload"
                type="file"
                onInput={handleUpload}
            />
            <div className="avatar-input flex-center">
                <div className="avatar-slot">
                    <div className="flex-center camera-icon">
                        <CameraIcon />
                    </div>
                    {pictureUrl && <img src={pictureUrl} alt="" />}
                </div>
                <p className="p1">
                    Загрузи фотографию, <br />
                    на которой хорошо видно твое лицо <br />
                    Рекомендуемый размер 384х460, соотношение 5:6
                </p>
            </div>
        </label>
    );
}

export default React.memo(AvatarInput);
