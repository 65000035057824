import { IPayloadAction } from 'store/type';
import { IAdminElection, IElection, IElectionState } from './types';

export function setRegistrationStatus(
    state: IElectionState,
    action: IPayloadAction<boolean>
): IElectionState {
    return {
        ...state,
        registrationStatus: action.payload,
    };
}

export function setTrueRegistrationStatus(
    state: IElectionState
): IElectionState {
    return {
        ...state,
        registrationStatus: true,
    };
}

export function setElections(
    state: IElectionState,
    action: IPayloadAction<IElection[]>
): IElectionState {
    return {
        ...state,
        elections: action.payload,
    };
}

export function setAdminElections(
    state: IElectionState,
    action: IPayloadAction<IAdminElection[]>
): IElectionState {
    return {
        ...state,
        adminElections: action.payload,
    };
}

export function setAdminElection(
    state: IElectionState,
    action: IPayloadAction<IAdminElection>
): IElectionState {
    return {
        ...state,
        adminElection: action.payload,
    };
}

export function clearAdminElection(state: IElectionState): IElectionState {
    return {
        ...state,
        adminElection: undefined,
    };
}

export function setElection(
    state: IElectionState,
    action: IPayloadAction<IElection>
): IElectionState {
    return {
        ...state,
        election: action.payload,
    };
}

export function clearElection(state: IElectionState): IElectionState {
    return {
        ...state,
        election: undefined,
    };
}

export function clearElections(state: IElectionState): IElectionState {
    return {
        ...state,
        elections: [],
    };
}
