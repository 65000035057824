import { createAsyncThunk } from '@reduxjs/toolkit';
import getApiClient from 'js/apiClient';
import { IAdminElection, ICandidateForm, IElection, IVoteForm } from './types';

export const asyncCreateCandidate = createAsyncThunk<string, ICandidateForm>(
    'elections/createCandidate',
    async (form, { rejectWithValue }) => {
        const formData = new FormData();
        Object.entries(form).forEach(([key, value]) => {
            formData.append(key, value);
        });
        return getApiClient('user')
            .post('elections/candidates/', formData)
            .then(() => {
                return 'ok';
            })
            .catch(error => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const asyncFetchRegistrationStatus = createAsyncThunk<boolean, void>(
    'elections/fetchRegistrationStatus',
    async (_, { rejectWithValue }) => {
        return getApiClient('user')
            .get('elections/registration_status/')
            .then(response => {
                return response.data.status;
            })
            .catch(error => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const asyncFetchElections = createAsyncThunk<IElection[], void>(
    'elections/fetchElections',
    async (_, { rejectWithValue }) => {
        return getApiClient('user')
            .get('elections/')
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw rejectWithValue(error.message);
            });
    }
);

export const asyncFetchAdminElections = createAsyncThunk<
    IAdminElection[],
    void
>('elections/fetchAdminElections', async (_, { rejectWithValue }) => {
    return getApiClient('admin')
        .get('admin/elections/')
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw rejectWithValue(error.message);
        });
});

export const asyncFetchAdminElection = createAsyncThunk<IAdminElection, number>(
    'elections/fetchAdminElection',
    async (electionId, { rejectWithValue }) => {
        return getApiClient('admin')
            .get(`admin/elections/${electionId}/`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw rejectWithValue(error.message);
            });
    }
);

export const asyncFetchElection = createAsyncThunk<IElection, number>(
    'elections/fetchElection',
    async (id, { rejectWithValue }) => {
        return getApiClient('user')
            .get(`elections/${id}/`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw rejectWithValue(error.message);
            });
    }
);

export const asyncCreateVote = createAsyncThunk<string, IVoteForm>(
    'elections/createVote',
    async (form, { rejectWithValue }) => {
        const data = {
            candidate: form.candidate,
        };
        return getApiClient('user')
            .post(`elections/${form.election}/votes/`, data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw rejectWithValue(error.message);
            });
    }
);
