import React from 'react';
import './Input.scss';
import Button from 'components/ui/Button';

export interface InputProps {
    label: string;
    placeholder?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    value?: string;
    submitLabel?: string;
    onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    type?: 'text' | 'password';
    error?: boolean;
    setError?: React.Dispatch<React.SetStateAction<boolean>>;
}

function Input({
    label,
    placeholder,
    onChange,
    type,
    value,
    submitLabel,
    onSubmit,
    disabled,
    error,
    setError,
}: InputProps): React.ReactElement {
    return (
        <div className="input">
            <span className="label p2">{label}</span>
            <div className={`input-border flex-center ${error && 'error'}`}>
                <input
                    disabled={disabled}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    className="p1"
                    onFocus={() => {
                        if (setError) setError(false);
                    }}
                />
                {submitLabel && onSubmit && (
                    <Button
                        disabled={!value || disabled}
                        onClick={onSubmit}
                        size="xs"
                        color="green"
                    >
                        {submitLabel}
                    </Button>
                )}
            </div>
        </div>
    );
}

Input.defaultProps = {
    onChange: () => null,
    value: '',
    submitLabel: null,
    onSubmit: null,
    placeholder: '',
    disabled: false,
    type: 'text',
    error: false,
    setError: null,
};

export default React.memo(Input);
