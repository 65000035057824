import React from 'react';
import './LoginPage.scss';
import ActionCard from 'components/elections/ActionCard';
import Chip from 'components/ui/Chip';
import { ReactComponent as CalendarIcon } from 'assets/icons/mingcute_calendar-fill.svg';
import { useLayoutContext } from 'pages/admin/AdminOutlet';

function LoginPage(): React.ReactElement {
    const { setOpenLoginForm } = useLayoutContext();

    const chips = React.useMemo(() => {
        return [
            <Chip key={1} icon={<CalendarIcon />}>
                Панель администратора
            </Chip>,
            <Chip key={2} icon={<CalendarIcon />}>
                Статистика выборов
            </Chip>,
        ];
    }, []);

    return (
        <ActionCard
            chips={chips}
            showIcon
            headerWidth="70%"
            actionText="Войти в админку"
            onClick={() => setOpenLoginForm(true)}
        >
            Заголовок для привлечения внимания
        </ActionCard>
    );
}

export default React.memo(LoginPage);
