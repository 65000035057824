import React from 'react';
import './ElectionCard.scss';
import { IElection } from 'store/reducers/elections/types';
import Button from 'components/ui/Button';
import { Link } from 'react-router-dom';
import Chip from 'components/ui/Chip';
import dayjs from 'dayjs';
import { ReactComponent as ProfileIcon } from 'assets/icons/Profile.svg';

function getActionButtonText(election: IElection): string {
    return election.is_active
        ? 'Завершить голосование'
        : 'Запустить голосование';
}

function getStartedText(election: IElection): string {
    return election.is_active
        ? 'Голосование запущено'
        : 'Голосование еще не запущено';
}

function ElectionCard({
    election,
}: {
    election: IElection;
}): React.ReactElement {
    const { name: header, date_start, date_end, candidates } = election;
    const [actionButtonText, setActionButtonText] = React.useState(
        getActionButtonText(election)
    );
    const [startedText, setStartedText] = React.useState(
        getStartedText(election)
    );
    React.useEffect(() => {
        setActionButtonText(getActionButtonText(election));
        setStartedText(getStartedText(election));
    }, [election, election.is_active]);

    return (
        <div className="admin-election-card card-1">
            <h2>{header}</h2>
            <h4>Студенческий совет {election.faculty?.name || 'СПбГУТ'}</h4>
            <div className="chips-list">
                <Chip icon={<ProfileIcon />}>
                    {candidates.length} кандидатов
                </Chip>
                <Chip icon={<ProfileIcon />}>
                    {dayjs(date_start).format('DD MMMM')}
                </Chip>
                <Chip icon={<ProfileIcon />}>
                    с {dayjs(date_start).format('HH:mm ')}
                    до {dayjs(date_end).format('HH:mm')}
                </Chip>
                <Chip icon={<ProfileIcon />}>{startedText}</Chip>
            </div>
            <div className="actions">
                <Link to={`elections/${election.id}`}>
                    <Button size="m" color="white">
                        К панель управления выборами
                    </Button>
                </Link>
                <Button size="m" color="black" onClick={() => null}>
                    {actionButtonText}
                </Button>
                <Link to={`elections/${election.id}/results`}>
                    <Button size="m" color="white">
                        К результатам
                    </Button>
                </Link>
            </div>
        </div>
    );
}

export default React.memo(ElectionCard);
