import { createSlice } from '@reduxjs/toolkit';
import {
    asyncFetchAdminElection,
    asyncFetchAdminElections,
    asyncFetchElection,
    asyncFetchElections,
    asyncFetchRegistrationStatus,
} from 'store/reducers/elections/thunks';
import {
    clearAdminElection as clearAdminElectionReducer,
    clearElection as clearElectionReducer,
    setAdminElection,
    setAdminElections,
    setElection,
    setElections,
    setRegistrationStatus,
    setTrueRegistrationStatus,
} from 'store/reducers/elections/reducer';
import { IElectionState } from './types';

const initialState: IElectionState = {
    registrationStatus: true,
    elections: [],
    adminElections: [],
};
export const reducers = {
    clearElectionReducer,
    clearAdminElectionReducer,
};

const slice = createSlice({
    name: 'settings',
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(
            asyncFetchRegistrationStatus.fulfilled,
            setRegistrationStatus
        );
        builder.addCase(
            asyncFetchRegistrationStatus.rejected,
            setTrueRegistrationStatus
        );
        builder.addCase(asyncFetchElections.fulfilled, setElections);
        builder.addCase(asyncFetchElection.fulfilled, setElection);
        builder.addCase(asyncFetchAdminElections.fulfilled, setAdminElections);
        builder.addCase(asyncFetchAdminElection.fulfilled, setAdminElection);
    },
});

export const {
    clearElectionReducer: clearElection,
    clearAdminElectionReducer: clearAdminElection,
} = slice.actions;
export default slice.reducer;
