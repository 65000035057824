import React from 'react';
import './CandidateResultCard.scss';
import { IAdminCandidate } from 'store/reducers/elections/types';
import { ReactComponent as ProfileIcon } from 'assets/icons/Profile.svg';
import { ReactComponent as ChartIcon } from 'assets/icons/Chart.svg';
import Chip from 'components/ui/Chip';

function CandidateResultCard({
    candidate,
    is_winner,
}: {
    candidate: IAdminCandidate;
    is_winner?: boolean;
}): React.ReactElement {
    const percents =
        candidate.percentage && (candidate.percentage * 100).toFixed(2);

    return (
        <div
            className={`candidate-result-card card-2 ${
                is_winner ? 'is_winner' : ''
            }`}
        >
            <img
                src={candidate.picture || 'https://i.ibb.co/KrQtWrM/Photo.png'}
                alt=""
            />
            <div className="headers">
                {is_winner && (
                    <>
                        <h2>
                            Победитель: <br />
                            {candidate.first_name} {candidate.last_name}
                        </h2>
                        <h4>Поздравляем нового председателя!</h4>
                    </>
                )}
                {!is_winner && (
                    <h4>
                        {candidate.first_name} {candidate.last_name}
                    </h4>
                )}
            </div>
            <div className="chips-list">
                <Chip icon={<ProfileIcon />}>
                    {candidate.votes_count} голоса
                </Chip>
                <Chip icon={<ChartIcon />}>{percents}% от всех голосов</Chip>
            </div>
        </div>
    );
}

CandidateResultCard.defaultProps = {
    is_winner: false,
};

export default React.memo(CandidateResultCard);
