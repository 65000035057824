import React from 'react';
import './SuccessCard.scss';
import { Link } from 'react-router-dom';
import Button from 'components/ui/Button';

function SuccessCard(): React.ReactElement {
    return (
        <div className="success-card white card-1">
            <h1>
                Анкета успешно отправлена, <br />
                удачи на выборах!
            </h1>
            <Link to="/">
                <Button size="m" color="black">
                    На главную
                </Button>
            </Link>
        </div>
    );
}

export default React.memo(SuccessCard);
