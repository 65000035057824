import React from 'react';
import './CandidatePage.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { useAppSelector } from 'store/store';
import Input from 'components/ui/Input';
import { ReactComponent as GreenArrowIcon } from 'assets/icons/GreenArrow.svg';

import { ReactComponent as VKIcon } from 'assets/icons/VK.svg';
import { ReactComponent as TGIcon } from 'assets/icons/TG.svg';
import Button from 'components/ui/Button';

function isOverflown({
    clientWidth,
    clientHeight,
    scrollWidth,
    scrollHeight,
}: HTMLInputElement): boolean {
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
}

function useDescriptionTexts(): [
    React.LegacyRef<HTMLInputElement>,
    React.LegacyRef<HTMLInputElement>,
    boolean,
    boolean,
    boolean,
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
    React.Dispatch<React.SetStateAction<boolean>>
] {
    const [showProgram, setShowProgram] = React.useState(false);
    const [showDescription, setShowDescription] = React.useState(false);

    const programRef = React.useRef<HTMLInputElement>(null);
    const descriptionRef = React.useRef<HTMLInputElement>(null);

    const [showProgramButton, setShowProgramButton] = React.useState(false);
    const [showDescriptionButton, setShowDescriptionButton] =
        React.useState(false);

    React.useEffect(() => {
        if (!programRef.current || !descriptionRef.current) return;
        setShowProgramButton(isOverflown(programRef.current));
        setShowDescriptionButton(isOverflown(descriptionRef.current));
    }, []);
    return [
        programRef,
        descriptionRef,
        showDescription,
        showProgram,
        showDescriptionButton,
        showProgramButton,
        setShowDescription,
        setShowProgram,
    ];
}

function CandidatePage(): React.ReactElement {
    const { candidateId } = useParams();
    const { election } = useAppSelector(store => store.electionsStore);
    const candidate = React.useMemo(() => {
        if (!election) return undefined;
        return election.candidates.find(
            _candidate => _candidate.id === Number(candidateId)
        );
    }, [candidateId, election]);

    const navigate = useNavigate();

    const [
        programRef,
        descriptionRef,
        showDescription,
        showProgram,
        showDescriptionButton,
        showProgramButton,
        setShowDescription,
        setShowProgram,
    ] = useDescriptionTexts();

    if (!election) return <span />;
    if (!candidate) return <span />;

    return (
        <div className="candidate-page width-wrapper">
            <div className="grid">
                <h3>
                    {candidate.first_name} {candidate.last_name}
                </h3>
                <img src={candidate.picture} alt="" />
                <div className="card-2 personal-info">
                    <h4>Личная информация</h4>
                    <div className="info-grid">
                        <Input
                            disabled
                            value={candidate.age.toString()}
                            label="Возраст"
                        />
                        <Input
                            disabled
                            value={candidate.group.faculty.name}
                            label="Факультет"
                        />
                        <Input
                            disabled
                            value={candidate.course.toString()}
                            label="Курс"
                        />
                        <Input
                            disabled
                            value={candidate.group.name}
                            label="Группа"
                        />
                    </div>
                </div>

                <div className="card-2 contacts">
                    <h4>Контакты</h4>
                    <div className="justify-between d-flex">
                        {candidate.vk_nick && (
                            <a
                                href={candidate.vk_nick}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <VKIcon />
                            </a>
                        )}
                        {candidate.telegram_nick && (
                            <a
                                href={candidate.telegram_nick}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <TGIcon />
                            </a>
                        )}
                    </div>
                </div>

                <div className="card-2 actions">
                    <h4>Быстрые действия</h4>
                    <div className="actions_buttons">
                        <Button
                            block
                            size="m"
                            color="white"
                            onClick={() =>
                                navigate(`/elections/${election.id}`)
                            }
                        >
                            Назад к кандидатам
                        </Button>
                        {!election.voted && (
                            <Link to="#vote-card">
                                <Button block size="m" color="green">
                                    К голосованию
                                </Button>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <div className="programs">
                <div className={`card-2 ${showDescription ? '' : 'hidden'}`}>
                    <h4>О кандидате</h4>
                    <p ref={descriptionRef}>{candidate.description_text}</p>
                    {showDescriptionButton && (
                        <div className="flex-center">
                            <button
                                type="button"
                                onClick={() => setShowDescription(true)}
                            >
                                Показать полностью <GreenArrowIcon />
                            </button>
                        </div>
                    )}
                </div>
                <div className={`card-2 ${showProgram ? '' : 'hidden'}`}>
                    <h4>Предвыборная программа</h4>
                    <p ref={programRef}>{candidate.program_text}</p>
                    {showProgramButton && (
                        <div className="flex-center">
                            <button
                                type="button"
                                onClick={() => setShowProgram(true)}
                            >
                                Показать полностью <GreenArrowIcon />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default React.memo(CandidatePage);
