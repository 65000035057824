import React from 'react';
import 'components/elections/ActionCard/ActionCard.scss';
import Button from 'components/ui/Button';
import { ReactComponent as SSLogoElement } from 'assets/icons/SSLogoElement.svg';
import { FacultyStyleMap } from 'store/reducers/user/data';
import { FacultyName } from 'store/reducers/user/types';

export interface ElectionCardProps {
    children: React.ReactNode[] | React.ReactNode;
    subHeader?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    actionText?: string;
    actionDisabled?: boolean;
    showIcon?: boolean;
    chips?: React.ReactElement[] | React.ReactElement;
    chipsGrid?: boolean;
    headerWidth?: string;
    type?: FacultyName;
    id?: string;
}

function ActionCard({
    children,
    onClick,
    actionText,
    actionDisabled,
    subHeader,
    showIcon,
    chips,
    chipsGrid,
    headerWidth,
    type,
    id,
}: ElectionCardProps): React.ReactElement {
    const facultyStyle = type ? FacultyStyleMap[type] : undefined;

    return (
        <div
            id={id}
            className="election-card card-2"
            style={{ background: facultyStyle?.background }}
        >
            {showIcon && (
                <div className="bg-icon">
                    {!!facultyStyle && <img src={facultyStyle.image} alt="" />}
                    {!facultyStyle && <SSLogoElement />}
                </div>
            )}
            <div className="election-card-grid">
                <div
                    className="headers"
                    style={{
                        maxWidth: headerWidth || undefined,
                    }}
                >
                    <h2>{children}</h2>
                    <h4>{subHeader}</h4>
                </div>
                <div
                    className={`election-card_chips ${
                        chipsGrid ? 'chips-grid' : ''
                    } ${facultyStyle ? 'dark' : ''}`}
                >
                    {chips}
                </div>
                {onClick && actionText && (
                    <Button
                        disabled={actionDisabled}
                        onClick={onClick}
                        color="black"
                        size="m"
                    >
                        {actionText}
                    </Button>
                )}
            </div>
        </div>
    );
}

ActionCard.defaultProps = {
    showIcon: false,
    onClick: undefined,
    actionText: undefined,
    subHeader: undefined,
    chipsGrid: false,
    headerWidth: undefined,
    actionDisabled: false,
    chips: [],
    id: undefined,
    type: undefined,
};

export default React.memo(ActionCard);
