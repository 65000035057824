import React from 'react';
import './LoginModal.scss';
import { ModalProps } from 'components/ui/Modal/Modal';
import Modal from 'components/ui/Modal';
import Chip from 'components/ui/Chip';
import { ReactComponent as LockIcon } from 'assets/icons/Lock.svg';
import { ReactComponent as GhostIcon } from 'assets/icons/mingcute_ghost-fill.svg';

interface LoginModalProps extends ModalProps {
    onCancel: () => void;
    header: React.ReactNode | React.ReactNode[];
    subHeader: React.ReactNode | React.ReactNode[];
    form: React.ReactElement;
}

function LoginModal({
    open,
    onCancel,
    header,
    subHeader,
    form,
}: LoginModalProps): React.ReactElement {
    return (
        <Modal className="auth-modal" onCancel={onCancel} open={open}>
            <div className="login-modal">
                <div className="headers">
                    <h2>{header}</h2>
                    <h4>{subHeader}</h4>
                </div>
                <div className="chips">
                    <Chip icon={<LockIcon />}>
                        Ваши данные надёжно защищены
                    </Chip>
                    <Chip icon={<GhostIcon />}>
                        Голосование полностью конфиденциально
                    </Chip>
                </div>
                {form}
            </div>
        </Modal>
    );
}

export default React.memo(LoginModal);
