import React from 'react';
import './VoteModal.scss';
import { ICandidate, IElection } from 'store/reducers/elections/types';
import Modal from 'components/ui/Modal';
import { ModalProps } from 'components/ui/Modal/Modal';
import { ReactComponent as GhostIcon } from 'assets/icons/mingcute_ghost-fill.svg';
import Chip from 'components/ui/Chip';
import CandidateVoteCard from 'components/elections/CandidateVoteCard';
import Button from 'components/ui/Button';
import ProtivVsehImage from 'assets/images/protiv_vseh.png';

interface VoteModalProps extends ModalProps {
    election: IElection;
    onCancel: () => void;
    onSubmit: (arg: number) => void;
}

const fakeCandidate: ICandidate = {
    id: 0,
    first_name: 'Против',
    middle_name: '',
    last_name: 'всех',
    picture: ProtivVsehImage,
    program_text: '',
    description_text: '',
    vk_nick: '',
    telegram_nick: '',
    age: 22,
    course: 2,
    student_number: 123,
    group: {
        id: 1,
        name: '',
        faculty: {
            id: 1,
            short_name: '',
            name: 'ИСиТ',
        },
    },
};

function VoteModal({
    election,
    open,
    onSubmit,
    onCancel,
}: VoteModalProps): React.ReactElement {
    const [selectedCandidate, setSelectedCandidate] =
        React.useState<number>(-1);
    const [disabled, setDisabled] = React.useState(false);

    return (
        <Modal className="vote-modal" open={open} onCancel={onCancel}>
            <h2>Голосование</h2>
            <p className="p0">Выбери, кто станет новым Председателем</p>
            <Chip icon={<GhostIcon />}>
                Голосование полностью конфиденциально
            </Chip>
            <div className="candidates-votes-list">
                {election.candidates.map(candidate => (
                    <CandidateVoteCard
                        key={candidate.id}
                        value={selectedCandidate}
                        onChange={val => setSelectedCandidate(val)}
                        candidate={candidate}
                    />
                ))}
                <CandidateVoteCard
                    value={selectedCandidate}
                    onChange={val => setSelectedCandidate(val)}
                    candidate={fakeCandidate}
                />
            </div>
            <Button
                disabled={selectedCandidate < 0 || disabled}
                block
                onClick={async () => {
                    setDisabled(true);
                    await onSubmit(selectedCandidate);
                    setDisabled(false);
                }}
                size="m"
                color="green"
            >
                Проголосовать
            </Button>
        </Modal>
    );
}

export default React.memo(VoteModal);
