import React from 'react';
import './AdminElectionResultsPage.scss';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useParams } from 'react-router-dom';
import { asyncFetchAdminElection } from 'store/reducers/elections/thunks';
import CandidateResultCard from 'components/admin/CandidateResultCard';
import { clearAdminElection } from 'store/reducers/elections';

function AdminElectionResultsPage(): React.ReactElement {
    const dispatch = useAppDispatch();
    const { electionId } = useParams();

    React.useEffect(() => {
        dispatch(asyncFetchAdminElection(Number(electionId)));
        return () => {
            dispatch(clearAdminElection());
        };
    }, [dispatch, electionId]);

    const { adminElection } = useAppSelector(state => state.electionsStore);

    if (!adminElection) return <span />;

    return (
        <div className="admin-election-results-page">
            <div className="winner">
                {adminElection.candidates.slice(0, 1).map(candidate => (
                    <CandidateResultCard
                        is_winner
                        key={candidate.id}
                        candidate={candidate}
                    />
                ))}
            </div>
            <div className="candidates-grid">
                {adminElection.candidates.slice(1).map(candidate => (
                    <CandidateResultCard
                        key={candidate.id}
                        candidate={candidate}
                    />
                ))}
            </div>
        </div>
    );
}

export default React.memo(AdminElectionResultsPage);
