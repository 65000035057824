import React from 'react';
import './AdminElectionPage.scss';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
    asyncFetchAdminElection,
    asyncFetchAdminElections,
} from 'store/reducers/elections/thunks';
import { useParams, useSearchParams } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { IAdminElection } from 'store/reducers/elections/types';
import { ChartData } from 'chart.js';

const colorsMap = {
    РТС: '#FF3B3B',
    ИКСС: '#FF8800',
    ИСиТ: '#6600CC',
    СЦТ: '#FFCC00',
    ВУЦ: '#17A414',
    СПБКТ: '#00CFDE',
    ЦЭУБИ: '#0063F7',
    ФФП: '#28293D',
    ИМ: '#243D91',
};

function getData(
    adminElection: IAdminElection
): ChartData<'pie', number[], unknown> {
    return {
        labels: adminElection.faculties_map.map(faculty => faculty.name),
        datasets: [
            {
                label: 'Количество голосов',
                data: adminElection.faculties_map.map(
                    faculty => faculty.votes_count
                ),
                backgroundColor: adminElection.faculties_map.map(
                    faculty => colorsMap[faculty.name as keyof typeof colorsMap]
                ),
            },
        ],
    };
}

function getRotateData(
    adminElections: IAdminElection[]
): ChartData<'pie', number[], unknown> {
    return {
        labels: adminElections.map(
            election => election.faculty?.name || election.hostel?.name
        ),
        datasets: [
            {
                label: 'Количество голосов',
                data: adminElections.map(election =>
                    Number(election.turnout * 100)
                ),
                backgroundColor: adminElections.map(election =>
                    election.faculty
                        ? colorsMap[election.faculty.name]
                        : 'white'
                ),
            },
        ],
    };
}

function getReturnValues(countDown: number): [number, number, number, number] {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
    return [
        days < 0 ? 0 : days,
        hours < 0 ? 0 : hours,
        minutes < 0 ? 0 : minutes,
        seconds < 0 ? 0 : seconds,
    ];
}

function addZero(number: number): string {
    return number.toString().length === 1
        ? `0${number.toString()}`
        : number.toString();
}

function useCountdown(
    targetDate: string | undefined
): [string, string, string, string] {
    const countDownDate = new Date(targetDate || new Date()).getTime();

    const [countDown, setCountDown] = React.useState(
        countDownDate - new Date().getTime()
    );

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    const [days, hours, minutes, seconds] = getReturnValues(countDown);
    return [addZero(days), addZero(hours), addZero(minutes), addZero(seconds)];
}

function AdminElectionPage(): React.ReactElement {
    const dispatch = useAppDispatch();
    const { electionId } = useParams();
    const [searchParams] = useSearchParams();
    const rotate = searchParams.get('rotate');
    const { adminElections } = useAppSelector(state => state.electionsStore);
    React.useEffect(() => {
        if (rotate) dispatch(asyncFetchAdminElections());
    }, [dispatch, rotate]);

    const filteredElections = React.useMemo(
        () => adminElections.filter(election => election.is_active),
        [adminElections]
    );

    React.useEffect(() => {
        let interval: NodeJS.Timer;
        if (rotate) {
            if (adminElections) {
                const electionsIds = filteredElections.map(
                    election => election.id
                );
                let currentElectionIndex = 0;
                interval = setInterval(() => {
                    dispatch(
                        asyncFetchAdminElection(
                            electionsIds[currentElectionIndex]
                        )
                    );
                    currentElectionIndex += 1;
                    if (currentElectionIndex === electionsIds.length)
                        currentElectionIndex = 0;
                }, 5000);
            }
        } else {
            interval = setInterval(() => {
                dispatch(asyncFetchAdminElection(Number(electionId)));
            }, 5000);
        }
        return () => clearInterval(interval);
    }, [dispatch, electionId, filteredElections, rotate]);

    const { adminElection } = useAppSelector(state => state.electionsStore);

    const [, hours, minutes, seconds] = useCountdown(adminElection?.date_end);

    if (!adminElection) return <span />;

    return (
        <div className="admin-election-page">
            <h3>Статистика по выборам {adminElection.faculty?.name}</h3>
            <div className="statistics-grid">
                <div
                    style={{ gridArea: 'a' }}
                    className="statistics-grid_card card-2"
                >
                    <h3>{(adminElection.turnout * 100).toFixed(2)}%</h3>
                    <h4>явка на выборах</h4>
                    <p>Всего студентов: {adminElection.students_count}</p>
                </div>
                <div
                    style={{ gridArea: 'b' }}
                    className="statistics-grid_card card-2"
                >
                    <h3>
                        {hours}:{minutes}:{seconds}
                    </h3>
                    <h4>до конца голосования</h4>
                </div>
                <div
                    style={{ gridArea: 'c' }}
                    className="statistics-grid_card card-2"
                >
                    <h3>
                        {adminElection.employees_votes_count +
                            adminElection.students_votes_count}
                    </h3>
                    <h4>суммарно голосов</h4>
                </div>
                <div
                    style={{ gridArea: 'd' }}
                    className="statistics-grid_card card-2"
                >
                    <h3>{adminElection.students_votes_count}</h3>
                    <h4>студенческих голосов</h4>
                </div>
                <div
                    style={{ gridArea: 'e' }}
                    className="statistics-grid_card card-2"
                >
                    <h3>{adminElection.employees_votes_count}</h3>
                    <h4>голосов сотрудников</h4>
                </div>
                <div
                    style={{ gridArea: 'f', padding: '0 100px' }}
                    className="statistics-grid_card card-2 top-3-block"
                >
                    <Pie
                        options={{
                            plugins: {
                                legend: {
                                    position: 'right',
                                },
                            },
                        }}
                        style={{}}
                        data={
                            rotate
                                ? getRotateData(filteredElections)
                                : getData(adminElection)
                        }
                    />
                </div>
                <div
                    style={{ gridArea: 'g' }}
                    className="statistics-grid_card card-2 top-3-block"
                >
                    <div className="top-3-block_headers">
                        {Object.entries(adminElection.courses_map)
                            .sort(([, countA], [, countB]) => {
                                if (countA > countB) return -1;
                                if (countB > countA) return 1;
                                return 0;
                            })
                            .slice(0, 5)
                            .map(([course, count]) => {
                                return (
                                    <h4 key={course}>
                                        {course} курс
                                        <span
                                            style={{
                                                marginLeft: '12px',
                                            }}
                                        >
                                            {count} голосов
                                        </span>
                                    </h4>
                                );
                            })}
                    </div>
                    <h4>
                        Рейтинг курсов <br /> по количеству голосов
                    </h4>
                </div>
                <div
                    style={{ gridArea: 'h' }}
                    className="statistics-grid_card card-2 top-3-block"
                >
                    <div className="top-3-block_headers">
                        {adminElection.groups_map.map(group => {
                            return (
                                <h4 key={group.name}>
                                    {group.name}
                                    <span
                                        style={{
                                            marginLeft: '12px',
                                        }}
                                    >
                                        {group.votes_count} голосов
                                    </span>
                                </h4>
                            );
                        })}
                    </div>
                    <h4>
                        Топ-6 групп <br /> по количеству голосов
                    </h4>
                </div>
            </div>
        </div>
    );
}

export default React.memo(AdminElectionPage);
