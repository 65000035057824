import React from 'react';
import './ElectionsPage.scss';
import { useAppDispatch, useAppSelector } from 'store/store';
import { asyncFetchAdminElections } from 'store/reducers/elections/thunks';
import ElectionCard from 'components/admin/ElectionCard';

function ElectionsPage(): React.ReactElement {
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(asyncFetchAdminElections());
    }, [dispatch]);

    const { adminElections } = useAppSelector(state => state.electionsStore);

    if (!adminElections) return <span />;

    return (
        <div className="elections-page">
            <h3>Доступные выборы для редактирования</h3>
            <div className="elections-page_list">
                {adminElections.map(election => (
                    <ElectionCard key={election.id} election={election} />
                ))}
            </div>
        </div>
    );
}

export default React.memo(ElectionsPage);
