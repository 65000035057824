import React from 'react';
import './styles/App.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import routes from './routes';

function App(): React.ReactElement {
    const router = createBrowserRouter(routes);
    return (
        <div className="App">
            <ConfigProvider locale={ruRU}>
                <RouterProvider router={router} />
            </ConfigProvider>
        </div>
    );
}

export default App;
