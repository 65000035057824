import React from 'react';
import './CandidateVoteCard.scss';
import { ICandidate } from 'store/reducers/elections/types';
import Checkbox from 'components/ui/Checkbox';

export interface CandidateVoteCardProps {
    candidate: ICandidate;
    value: number | undefined;
    onChange: (value: number) => void;
}

function CandidateVoteCard({
    candidate,
    value,
    onChange,
}: CandidateVoteCardProps): React.ReactElement {
    const selected = candidate.id === (value || 0);
    return (
        <div
            role="button"
            onKeyDown={() => null}
            tabIndex={0}
            className="candidate-vote-card"
            onClick={() => onChange(candidate.id)}
        >
            <img
                className={`${selected ? '' : 'greyed'}`}
                src={candidate.picture}
                alt=""
            />
            <Checkbox value={selected} />
            <h5>
                {candidate.first_name} {candidate.last_name}
            </h5>
        </div>
    );
}

export default React.memo(CandidateVoteCard);
