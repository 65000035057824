import React from 'react';
import './Checkbox.scss';
import { ReactComponent as Check } from 'assets/icons/mingcute_check-line.svg';

export interface CheckboxProps {
    value: boolean;
    onChange?: (val: boolean) => void;
    children?: React.ReactNode;
}

function Checkbox({
    value,
    onChange,
    children,
}: CheckboxProps): React.ReactElement {
    const checked = value ? 'checked' : '';
    return (
        <div
            tabIndex={0}
            onClick={() => onChange?.(!value)}
            role="button"
            onKeyDown={() => null}
            className="checkbox"
        >
            <div className={`checkbox-input ${checked}`}>
                <Check />
            </div>
            <span className="label">{children}</span>
        </div>
    );
}

Checkbox.defaultProps = {
    onChange: () => null,
    children: '',
};

export default React.memo(Checkbox);
