import React from 'react';
import './Question.scss';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

export interface QuestionProps {
    header: string;
    text: React.ReactNode | React.ReactNode[];
}

function Question({ header, text }: QuestionProps): React.ReactElement {
    const [hidden, setHidden] = React.useState(true);

    return (
        <div
            className={`question ${hidden ? 'hidden' : ''}`}
            tabIndex={0}
            role="button"
            onKeyDown={() => null}
        >
            <h4>{header}</h4>
            <p className="p1">{text}</p>
            <button type="button" onClick={() => setHidden(!hidden)}>
                <CrossIcon />
            </button>
        </div>
    );
}

export default React.memo(Question);
