import React from 'react';
import './CompletedVoteModal.scss';
import { ModalProps } from 'components/ui/Modal/Modal';
import { ReactComponent as HeartIcon } from 'assets/icons/Heart.svg';
import { ReactComponent as LargeSSIcon } from 'assets/icons/LargeSS.svg';
import Modal from 'components/ui/Modal';
import Chip from 'components/ui/Chip';

interface CompletedVoteModalProps extends ModalProps {
    onCancel: () => void;
}

function CompletedVoteModal({
    open,
    onCancel,
}: CompletedVoteModalProps): React.ReactElement {
    return (
        <Modal className="completed-vote-modal" open={open} onCancel={onCancel}>
            <h2>
                Ваш голос <br />
                успешно учтён
            </h2>
            <p className="p0">
                Результаты выборов будут объявлены в 18:00 по Московскому
                времени
            </p>
            <Chip icon={<HeartIcon />}>Спасибо за участие в голосовании</Chip>
            <div className="ss-icon">
                <LargeSSIcon />
            </div>
        </Modal>
    );
}

export default React.memo(CompletedVoteModal);
