import React from 'react';
import './Modal.scss';
import { Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

export type ModalProps = AntModalProps;

function Modal({
    open,
    onCancel,
    children,
    className,
}: ModalProps): React.ReactElement {
    return (
        <AntModal
            closeIcon={null}
            onCancel={onCancel}
            footer={null}
            open={open}
            width={2000}
            className={`${className} modal`}
        >
            {children}
            <button
                className="close-button flex-center"
                type="button"
                onClick={onCancel}
            >
                <CrossIcon />
            </button>
        </AntModal>
    );
}

export default React.memo(Modal);
