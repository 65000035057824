import FP from 'assets/images/FP.png';
import ISiT from 'assets/images/ISiT.png';
import IKSS from 'assets/images/IKSS.png';
import RTS from 'assets/images/RTS.png';
import SCT from 'assets/images/SCT.png';
import VUC from 'assets/images/VUC.png';
import IM from 'assets/images/IM.png';
import KT from 'assets/images/KT.png';
import TS from 'assets/images/TS.png';
import { FacultyName } from 'store/reducers/user/types';

export interface IFacultyStyle {
    image: string;
    background: string;
}

type IFacultyStyleMap = {
    [k in FacultyName]: IFacultyStyle;
};

export const FacultyStyleMap: IFacultyStyleMap = {
    ИСиТ: {
        image: ISiT,
        background: 'linear-gradient(331deg, #8A33FF 19.47%, #3300B8 100%)',
    },
    ИКСС: {
        image: IKSS,
        background: 'linear-gradient(329.76deg, #FF7918 9.64%, #BD3900 100%)',
    },
    РТС: {
        image: RTS,
        background: 'linear-gradient(333.42deg, #C71205 13%, #960012 100%)',
    },
    СЦТ: {
        image: SCT,
        background: 'linear-gradient(328.75deg, #EFDE45 -1.68%, #EBA900 100%)',
    },
    ЦЭУБИ: {
        image: TS,
        background: 'linear-gradient(324.7deg, #3656FF 15.2%, #0008C0 100%)',
    },
    ФФП: {
        image: FP,
        background: 'linear-gradient(328.98deg, #343434 25.23%, #101010 100%)',
    },
    ВУЦ: {
        image: VUC,
        background: 'linear-gradient(327.62deg, #7D9E32 11.21%, #1D6927 100%)',
    },
    ИМ: {
        image: IM,
        background: 'linear-gradient(150.81deg, #26275C 0%, #3476B7 81.55%)',
    },
    СПБКТ: {
        image: KT,
        background: 'linear-gradient(332.64deg, #10EBFF 16.28%, #0083C7 100%)',
    },
};
