import React from 'react';
import './TextArea.scss';

export interface TextAreaProps {
    label: string;
    placeholder: string;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
    value?: string;
}

function TextArea({
    label,
    placeholder,
    onChange,
    value,
}: TextAreaProps): React.ReactElement {
    return (
        <div className="textarea-wrapper">
            <span className="label">{label}</span>
            <textarea
                rows={10}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    );
}

TextArea.defaultProps = {
    onChange: () => null,
    value: '',
};

export default React.memo(TextArea);
