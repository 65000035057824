import React from 'react';
import 'components/elections/CandidateCard/CandidateCard.scss';
import { ICandidate } from 'store/reducers/elections/types';
import { ReactComponent as GreenArrowIcon } from 'assets/icons/GreenArrow.svg';
import { useNavigate } from 'react-router-dom';

function CandidateCard({
    candidate,
}: {
    candidate: ICandidate;
}): React.ReactElement {
    const { first_name, last_name, picture, course, group } = candidate;
    const navigate = useNavigate();
    return (
        <div className="candidate card-2">
            <img src={picture} alt="" />
            <button
                type="button"
                onClick={() => navigate(`candidates/${candidate.id}`)}
            >
                Подробнее о кандидате <GreenArrowIcon />
            </button>
            <div className="description flex-center">
                <h4 className="name bold">
                    {first_name} {last_name}
                </h4>
                <p className="p2">
                    {group.faculty.name} | {course} курс | {group.name}
                </p>
            </div>
        </div>
    );
}

export default React.memo(CandidateCard);
