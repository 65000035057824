import { createSlice } from '@reduxjs/toolkit';
import { IUserState } from './types';
import {
    asyncSendStudentCode,
    asyncSendEmployeeCode,
    asyncSendEmail,
    asyncFetchUser,
    asyncFetchAdminUser,
    asyncAdminLogin,
} from './thunks';
import {
    setUser,
    setCodeSent,
    setToken,
    logOut as logOutReducer,
    setAdminUser,
    setAdminToken,
} from './reducer';

const initialState: IUserState = {
    user: undefined,
    codeSent: false,
};
export const reducers = {
    logOutReducer,
};

const slice = createSlice({
    name: 'settings',
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(asyncSendEmail.fulfilled, setCodeSent);
        builder.addCase(asyncSendStudentCode.fulfilled, setToken);
        builder.addCase(asyncSendEmployeeCode.fulfilled, setToken);
        builder.addCase(asyncFetchUser.fulfilled, setUser);
        builder.addCase(asyncFetchAdminUser.fulfilled, setAdminUser);
        builder.addCase(asyncAdminLogin.fulfilled, setAdminToken);
    },
});

export const { logOutReducer: logOut } = slice.actions;
export default slice.reducer;
