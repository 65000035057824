import React from 'react';
import './Chip.scss';

export interface ChipProps {
    icon: React.ReactNode;
    children: React.ReactNode;
}

function Chip({ icon, children }: ChipProps): React.ReactElement {
    return (
        <div className="chip flex-center">
            <span className="chip-icon flex-center">{icon}</span>
            <span className="chip-text black flex-center">{children}</span>
        </div>
    );
}

export default React.memo(Chip);
