import { createAsyncThunk } from '@reduxjs/toolkit';
import getApiClient from 'js/apiClient';
import { IAdminForm, IAdminUser, IUser } from './types';

export const asyncSendEmail = createAsyncThunk<string, string>(
    'users/sendEmail',
    async (email, { rejectWithValue }) => {
        const data = {
            email,
        };
        return getApiClient()
            .post('user/student/email/', data)
            .then(() => {
                return 'ok';
            })
            .catch(error => {
                throw rejectWithValue(error.message);
            });
    }
);

export const asyncSendStudentCode = createAsyncThunk<string, string>(
    'users/sendStudentCode',
    async (code, { rejectWithValue }) => {
        const data = { code };
        return getApiClient()
            .post('user/student/code/', data)
            .then(response => {
                return response.data.token;
            })
            .catch(error => {
                throw rejectWithValue(error.message);
            });
    }
);

export const asyncSendEmployeeCode = createAsyncThunk<string, string>(
    'users/sendEmployeeCode',
    async (code, { rejectWithValue }) => {
        const data = { code };
        return getApiClient()
            .post('user/employee/code/', data)
            .then(response => {
                return response.data.token;
            })
            .catch(error => {
                throw rejectWithValue(error.message);
            });
    }
);

export const asyncFetchUser = createAsyncThunk<IUser, void>(
    'users/fetchUser',
    async (_, { rejectWithValue }) => {
        return getApiClient('user')
            .get('user/session/')
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw rejectWithValue(error.message);
            });
    }
);

export const asyncFetchAdminUser = createAsyncThunk<IAdminUser, void>(
    'users/fetchAdminUser',
    async (_, { rejectWithValue }) => {
        return getApiClient('admin')
            .get('admin/session/')
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw rejectWithValue(error.message);
            });
    }
);

export const asyncAdminLogin = createAsyncThunk<string, IAdminForm>(
    'users/adminLogin',
    async (form, { rejectWithValue }) => {
        const data: IAdminForm = { ...form };
        return getApiClient()
            .post('admin/password/', data)
            .then(response => {
                return response.data.token;
            })
            .catch(error => {
                throw rejectWithValue(error.message);
            });
    }
);
