import React, { Dispatch } from 'react';
import './Header.scss';
import { ReactComponent as SSLogo } from 'assets/icons/ss.svg';
import { Link } from 'react-router-dom';
import Button from 'components/ui/Button';
import LoginModal from 'forms/modals/LoginModal';
import { logOut } from 'store/reducers/user';
import { useAppDispatch } from 'store/store';

function Header({
    username,
    dispatch,
    form,
    modalOpen,
    setModalOpen,
    header,
    subHeader,
}: {
    username: string | undefined;
    dispatch: ReturnType<typeof useAppDispatch>;
    form: React.ReactElement;
    modalOpen: boolean;
    header: React.ReactNode | React.ReactNode[];
    subHeader: React.ReactNode | React.ReactNode[];
    setModalOpen: Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement {
    return (
        <header>
            <div className="logo">
                <Link to="/">
                    <SSLogo />
                </Link>
            </div>
            <div className="profile flex-center">
                {!username && (
                    <Button
                        size="s"
                        color="black"
                        onClick={() => setModalOpen(true)}
                    >
                        Войти
                    </Button>
                )}
                {username && (
                    <>
                        <p>Здравствуйте, {username}</p>
                        <Button
                            size="s"
                            color="transparent"
                            onClick={() => dispatch(logOut())}
                        >
                            Выйти
                        </Button>
                    </>
                )}
            </div>

            <LoginModal
                footer={null}
                open={modalOpen}
                header={header}
                subHeader={subHeader}
                onCancel={() => setModalOpen(false)}
                form={form}
            />
        </header>
    );
}

export default React.memo(Header);
