import React from 'react';
import Header from 'layouts/header';
import Footer from 'layouts/footer';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store';
import { asyncFetchAdminUser } from 'store/reducers/user/thunks';
import AdminLoginForm from 'forms/forms/AdminLoginForm';

type ContextType = {
    setOpenLoginForm: React.Dispatch<React.SetStateAction<boolean>>;
};

function MainPage(): React.ReactElement {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const getData = React.useCallback(() => {
        dispatch(asyncFetchAdminUser());
    }, [dispatch]);

    React.useEffect(() => {
        if (localStorage.adminToken) {
            getData();
        }
    }, [dispatch, getData]);

    const { adminUser } = useAppSelector(state => state.userStore);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (!localStorage.adminToken) navigate('/admin/login');
    }, [adminUser, navigate]);

    const onSuccess = React.useCallback(async () => {
        await dispatch(asyncFetchAdminUser())
            .unwrap()
            .catch(error => console.log(error));
        setOpen(false);
        navigate('/admin');
    }, [dispatch, navigate]);

    return (
        <>
            <Header
                modalOpen={open}
                setModalOpen={setOpen}
                header={
                    <>
                        Авторизация <br />
                        для админов
                    </>
                }
                subHeader={
                    <>
                        После этого будет вам станет доступна панель <br />
                        администратора и статистика выборов в реальном времени
                    </>
                }
                form={<AdminLoginForm onSuccess={onSuccess} />}
                username={adminUser?.username}
                dispatch={dispatch}
            />
            <main>
                <Outlet context={{ setOpenLoginForm: setOpen }} />
            </main>
            <Footer />
        </>
    );
}

export function useLayoutContext(): ContextType {
    return useOutletContext<ContextType>();
}

export default React.memo(MainPage);
