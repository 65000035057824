import React from 'react';
import Header from 'layouts/header';
import Footer from 'layouts/footer';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store';
import { asyncFetchUser } from 'store/reducers/user/thunks';
import { asyncFetchRegistrationStatus } from 'store/reducers/elections/thunks';
import LoginForm from 'forms/forms/LoginForm';
import ScrollToTop from '../../components/helpers/ScrollToTop';

function MainPage(): React.ReactElement {
    const dispatch = useAppDispatch();

    const getData = React.useCallback(() => {
        dispatch(asyncFetchUser());
        dispatch(asyncFetchRegistrationStatus());
    }, [dispatch]);

    React.useEffect(() => {
        if (localStorage.userToken) {
            getData();
        }
    }, [dispatch, getData]);

    const { user } = useAppSelector(state => state.userStore);
    const [open, setOpen] = React.useState(false);

    const onSuccess = React.useCallback(async () => {
        setOpen(false);
        await dispatch(asyncFetchUser()).unwrap();
        await dispatch(asyncFetchRegistrationStatus()).unwrap();
    }, [dispatch]);

    const username = user
        ? `${user?.first_name} ${user?.last_name}`
        : undefined;

    return (
        <>
            <ScrollToTop />
            <Header
                form={<LoginForm onSuccess={onSuccess} />}
                username={username}
                header={
                    <>
                        Авторизация <br /> в системе выборов
                    </>
                }
                subHeader={
                    <>
                        После этого вам станет доступен расширенный функционал
                        <br />
                        сайта и открыт доступ к голосованию
                    </>
                }
                dispatch={dispatch}
                modalOpen={open}
                setModalOpen={setOpen}
            />
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    );
}

export default React.memo(MainPage);
