import React from 'react';
import './CandidateForm.scss';
import Chip from 'components/ui/Chip';
import { ReactComponent as EyeIcon } from 'assets/icons/mingcute_eye-2-fill.svg';
import Input from 'components/ui/Input';
import TextArea from 'components/ui/TextArea';
import Checkbox from 'components/ui/Checkbox';
import Button from 'components/ui/Button';
import AvatarInput from 'components/ui/AvatarInput';
import { ICandidateForm } from 'store/reducers/elections/types';

interface CandidateFormProps {
    setForm: React.Dispatch<React.SetStateAction<ICandidateForm>>;
    form: ICandidateForm;
    onSubmit: () => void;
}

function checkForm(form: ICandidateForm): boolean {
    const entries = Object.entries(form);
    for (let i = 0; i < entries.length; i += 1) {
        const [key, value] = entries[i];
        if (
            ['program_text', 'description_text', 'picture'].includes(key) &&
            !value
        )
            return false;
    }
    return true;
}

function CandidateForm({
    setForm,
    form,
    onSubmit,
}: CandidateFormProps): React.ReactElement {
    const { vk_nick, telegram_nick, description_text, program_text } = form;

    const [agreement, setAgreement] = React.useState(false);
    const [formFulfilled, setFormFulfilled] = React.useState(false);

    React.useEffect(() => {
        setFormFulfilled(checkForm(form));
    }, [form]);

    const formDisabled = !formFulfilled || !agreement;

    return (
        <div className="candidate-form card-1 bg-green white">
            <h2>Анкета для кандидатов</h2>
            <Chip icon={<EyeIcon />}>Эти данные увидят избиратели</Chip>
            <div className="card-3 bg-white">
                <AvatarInput
                    setFile={value => {
                        setForm(state => {
                            return { ...state, picture: value };
                        });
                    }}
                />
                <form>
                    <Input
                        value={vk_nick}
                        onChange={e =>
                            setForm(state => {
                                return { ...state, vk_nick: e.target.value };
                            })
                        }
                        placeholder="https://vk.com/durov"
                        label="Ссылка на твой профиль в VK"
                    />
                    <Input
                        value={telegram_nick}
                        onChange={e =>
                            setForm(state => {
                                return {
                                    ...state,
                                    telegram_nick: e.target.value,
                                };
                            })
                        }
                        placeholder="https://t.me/durov"
                        label="Ссылка на твой Telegram-аккаунт"
                    />
                    <TextArea
                        value={description_text}
                        onChange={e =>
                            setForm(state => {
                                return {
                                    ...state,
                                    description_text: e.target.value,
                                };
                            })
                        }
                        placeholder="Немного расскажи о себе: чем занимаешься
                        в университете, почему решил(а) баллотироваться"
                        label="Характеристика"
                    />
                    <TextArea
                        value={program_text}
                        onChange={e =>
                            setForm(state => {
                                return {
                                    ...state,
                                    program_text: e.target.value,
                                };
                            })
                        }
                        placeholder="Подробно расскажи о
                        своей предвыборной программе (не менее 1000 символов)"
                        label="Предвыборная программа"
                    />
                </form>
                <Checkbox
                    value={agreement}
                    onChange={value => setAgreement(value)}
                >
                    Я согласен(на) на обработку персональных данных
                </Checkbox>
                <Button
                    disabled={formDisabled}
                    onClick={onSubmit}
                    color="black"
                    size="m"
                    block
                >
                    Отправить
                </Button>
            </div>
        </div>
    );
}

export default React.memo(CandidateForm);
