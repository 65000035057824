import React from 'react';
import './AdminLoginForm.scss';
import Button from 'components/ui/Button';
import { useAppDispatch } from 'store/store';
import { IAdminForm } from 'store/reducers/user/types';
import Input from 'components/ui/Input';
import { asyncAdminLogin } from 'store/reducers/user/thunks';

function AdminLoginForm({
    onSuccess,
}: {
    onSuccess: () => void;
}): React.ReactElement {
    const dispatch = useAppDispatch();

    const [form, setForm] = React.useState<IAdminForm>({
        username: '',
        password: '',
    });

    return (
        <div className="login-form card-3">
            <Input
                value={form.username}
                onChange={e =>
                    setForm(state => ({ ...state, username: e.target.value }))
                }
                label="Логин"
            />
            <Input
                value={form.password}
                onChange={e =>
                    setForm(state => ({ ...state, password: e.target.value }))
                }
                type="password"
                label="Пароль"
            />
            <Button
                disabled={
                    form.password.length === 0 || form.username.length === 0
                }
                onClick={() =>
                    dispatch(asyncAdminLogin(form))
                        .unwrap()
                        .then(() => onSuccess())
                }
                color="green"
                size="m"
                block
            >
                Войти
            </Button>
        </div>
    );
}

export default React.memo(AdminLoginForm);
