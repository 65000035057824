import { IPayloadAction } from 'store/type';
import { IAdminUser, IUser, IUserState } from './types';

export function setUser(
    state: IUserState,
    action: IPayloadAction<IUser>
): IUserState {
    return {
        ...state,
        user: action.payload.employee || action.payload.student,
    };
}

export function setAdminUser(
    state: IUserState,
    action: IPayloadAction<IAdminUser>
): IUserState {
    return {
        ...state,
        adminUser: action.payload,
    };
}

export function setCodeSent(state: IUserState): IUserState {
    return {
        ...state,
        codeSent: true,
    };
}

export function setToken(
    state: IUserState,
    action: IPayloadAction<string>
): void {
    localStorage.userToken = action.payload;
}

export function setAdminToken(
    state: IUserState,
    action: IPayloadAction<string>
): void {
    localStorage.adminToken = action.payload;
}

export function logOut(state: IUserState): IUserState {
    localStorage.removeItem('userToken');
    return {
        ...state,
        user: undefined,
        adminUser: undefined,
    };
}
